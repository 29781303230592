'use client'
import * as React from 'react'
import { useLabProducts } from './useLabProducts'
import { getQueryParamString } from '../../../../../utilities/getQueryParamString'
import { typedFalsyFilter } from '../../../../../typescript/guards/typedFalsyFilter'
import { productSlugDynamicRouteSegment } from '../../../../../common/constants/dynamicRouteSegments'
import { useParams } from 'next/navigation'

enum packages {
  Basics = 'Basics',
  Core = 'Core',
  'Comprehensive Male Health' = 'Comprehensive Male Health',
  'Comprehensive Female Health' = 'Comprehensive Female Health',
  'Advanced Male Health' = 'Advanced Male Health',
  'Advanced Female Health' = 'Advanced Female Health',
  // New
  Foundation = 'Foundation',
  'Comprehensive Health' = 'Comprehensive Health',
  'Advanced Longevity' = 'Advanced Longevity',
}

export const upgradePackageOrder = [
  packages.Foundation,
  packages['Comprehensive Health'],
  packages['Advanced Longevity'],
] as const

const getComparePackages = (packageName: string) => {
  const dict = {
    Foundation: ['Comprehensive Health', 'Advanced Longevity'],
    'Comprehensive Health': ['Advanced Longevity'],
    'Advanced Longevity': [],
  } as Record<string, string[]>

  return dict[packageName] ?? []
}

export function useComparePackages() {
  const { packageData } = useLabProducts()
  const slug = getQueryParamString(useParams()?.[productSlugDynamicRouteSegment])

  const packageName = packageData.packages.find((x) => x.slug === slug)?.name

  return React.useMemo(() => {
    if (!packageName) return []
    const comparePackagesExcludingCurrent = getComparePackages(packageName).filter((x) => x !== packageName)

    return comparePackagesExcludingCurrent
      .map((x) => packageData.packages.find((y) => y.name === x))
      .filter(typedFalsyFilter)
  }, [packageName, packageData])
}
