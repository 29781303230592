import styled from '@emotion/styled'
import {
  canHoverCssMq,
  desktopDownMq,
  desktopUpMq,
  isTouchScreenCssMq,
  notebookDownMq,
  notebookUpMq,
  phoneDownMq,
  phoneUpMq,
  tabletDownMq,
  tabletToNotebookMq,
  tabletUpMq,
} from '../../styles/mediaQueries'
import { hasKey } from '../../typescript/guards/hasKey'

export interface DeviceMediaQueries {
  isPhoneDown?: boolean
  isPhoneUp?: boolean
  isTabletDown?: boolean
  isTabletUp?: boolean
  isNotebookDown?: boolean
  isTabletToNotebook?: boolean
  isNotebookUp?: boolean
  canHover?: boolean
  isTouchScreen?: boolean
}

export const mqKeyToValueDict = {
  isPhoneDown: phoneDownMq,
  isPhoneUp: phoneUpMq,
  isTabletDown: tabletDownMq,
  isTabletUp: tabletUpMq,
  isTabletToNotebook: tabletToNotebookMq,
  isNotebookDown: notebookDownMq,
  isNotebookUp: notebookUpMq,
  isDesktopDown: desktopDownMq,
  isDesktopUp: desktopUpMq,
  canHover: canHoverCssMq,
  isTouchScreen: isTouchScreenCssMq,
}

/**
 * Styled component (span) for displaying/hiding children. Takes in a string prop 'screen' of one of our breakpoint names (desktop,mobile, tablet,
 * widescreen) and will display the children within that breakpoint and hide them otherwise.
 */
export const Display = styled.span<DeviceMediaQueries>`
  display: none !important;

  ${(p) =>
    Object.entries(p).map(
      ([mqKey, value]) =>
        hasKey(mqKeyToValueDict, mqKey) &&
        `${mqKeyToValueDict[mqKey]} {display: ${
          value ? (p.style?.display ? `${p.style.display} !important` : 'initial !important') : 'none !important'
        }}`
    )}
`
