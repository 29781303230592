'use client'
import * as React from 'react'
import styled from '@emotion/styled'
import { UnstyledButton } from '../../../../../common/style/UnstyledButton'
import { transientOptions } from '../../../../../common/style/transientOptions'
import { theme } from '../../../../../styles/theme'
import { useAddPackageCtaProps } from '../hooks/useAddPackageCtaProps'
import { useShoppingCart } from '../../../hooks/useShoppingCart/useShoppingCart'
import { LazyLoadingSpinner } from '../../../../../common/components/LazyLoadingSpinner'
import { type CardColors } from '../utils/getPackageColor'
import { NextLink } from '../../../../../common/functionality/NextLink'
import { Styles, getCssProp } from '../../../../../utilities/getCssProp'
import {
  makeRouteFromDynamicSegmentSignature,
  shopLabsPackageDynamicSignature,
} from '../../../../../common/constants/routes'
import { analytics } from '../../../../analytics/analytics'
import { ClickEvent } from '../../../../../typescript/utility'
import { useIsMounted } from '../../../../../hooks/useIsMounted'
import { NavigationClose } from '../../../../../assets/components/NavigationClose'

const MainCta = styled(UnstyledButton, transientOptions)<{ $isLoading: boolean; $colors: CardColors }>`
  width: fill-available;
  position: relative;
  border-radius: 999px;
  display: flex;
  min-width: 164px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: ${theme.colors.grayscalewhite};
  background-color: ${(p) => p.$colors.buttonBg};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  transition:
    background-color 0.3s,
    opacity 0.3s;

  cursor: ${(p) => (p.$isLoading ? 'progress' : 'pointer')};
  :disabled {
    cursor: ${(p) => (p.$isLoading ? 'progress' : 'not-allowed')};
    opacity: 0.8;
  }
  :hover:not(:disabled) {
    background-color: ${(p) => p.$colors.buttonBgHover};
  }
`

const ViewDetailsButton = styled(UnstyledButton)<{ $colors: CardColors }>`
  padding: 6px 8px;
  color: ${(p) => p.$colors.text};
  text-align: center;
  margin-bottom: 4px;
  border-radius: 999px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s;

  :hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`
const ViewDetailsLink = styled(ViewDetailsButton, transientOptions)``.withComponent(NextLink)

type Props = {
  productId: string
  slug: string
  name: string
  ctaDefaultText?: string
  colors: CardColors
  styles?: { mainButton?: Styles; viewDetails?: Styles }
  nonLinkViewDetailsOnClick?: () => void
  viewDetailsChildren?: React.ReactNode
  isPackagePlp?: boolean
}

export function PlanCardButtons({
  productId,
  slug,
  name,
  colors,
  styles,
  ctaDefaultText,
  viewDetailsChildren = 'View Details',
  nonLinkViewDetailsOnClick,
  isPackagePlp = false,
}: Props) {
  const mainButtonCssProp = getCssProp(styles?.mainButton)
  const viewDetailsCssProp = getCssProp(styles?.viewDetails)
  const { loading, inFlightChangesProductIds } = useShoppingCart()

  const productRequestLoading = inFlightChangesProductIds.some((x) => x === productId)

  const { onClick, children, inCart, gtmDataLayerVariableDataAttributes } = useAddPackageCtaProps(
    productId && slug && name ? { productId, slug, name } : null
  )

  const pdpUrl = makeRouteFromDynamicSegmentSignature(shopLabsPackageDynamicSignature, slug)

  const isMounted = useIsMounted()

  // !!TODO look into this. using the css prop surfaces an emotion cache hydration mismatch error on mount no matter
  // what, even if i pass in a static object as the value. need to look up if a fix exists
  const viewDetailsSharedProps = {
    ...(isMounted && { css: viewDetailsCssProp }),
    $colors: colors,
    children: viewDetailsChildren,
  }

  return (
    <>
      <MainCta
        style={{ ...(isPackagePlp && { padding: '12px 20px' }) }}
        {...(isMounted && { css: mainButtonCssProp })}
        $isLoading={isMounted && productRequestLoading}
        disabled={isMounted && (loading || productRequestLoading)}
        $colors={colors}
        {...gtmDataLayerVariableDataAttributes}
        onClick={async () => {
          onClick()
        }}
      >
        {productRequestLoading && <LazyLoadingSpinner isSmall styles={{ position: 'absolute' }} />}

        {!inCart && ctaDefaultText ? (
          <div className="flex items-center justify-center gap-2">
            <NavigationClose isPlus color={theme.colors.grayscalewhite} size={12} />
            {ctaDefaultText}
          </div>
        ) : (
          children
        )}
      </MainCta>
      {nonLinkViewDetailsOnClick ? (
        <ViewDetailsButton
          {...viewDetailsSharedProps}
          onClick={nonLinkViewDetailsOnClick}
          style={{ padding: '12px 20px' }}
        />
      ) : (
        <ViewDetailsLink
          style={{ padding: '12px 20px' }}
          href={pdpUrl}
          {...viewDetailsSharedProps}
          onClick={(e: ClickEvent<HTMLElement>) => {
            e.stopPropagation()
            analytics.track(`User viewed details for the ${name} Lab Package`)
          }}
        />
      )}
    </>
  )
}
