'use client'
import * as React from 'react'

/**
 * `useMatchMedia` listens for changes in "match" state of `window.matchMedia`
 * returning `defaultState` during the first render.
 * @param query to use in `window.matchMedia` to test for matches.
 * @param defaultState to use before `matchMedia` has executed for the first
 * time.
 * @returns the current "match" state of `query` for the browser.
 * @see https://github.com/streamich/react-use/blob/0ba46f9cea8686b526a42f566afa1096e06a5f94/src/useMatchMedia.ts
 */
export function useMatchMedia(query: string, defaultState: boolean = false) {
  const [state, setState] = React.useState(defaultState)

  React.useEffect(() => {
    let mounted = true
    const mql = window.matchMedia(query.replace('@media', ''))
    const onChange = () => {
      if (!mounted) {
        return
      }
      setState(!!mql.matches)
    }

    mql.addEventListener('change', onChange)
    setState(mql.matches)

    return () => {
      mounted = false
      mql.removeEventListener('change', onChange)
    }
  }, [query])

  return state
}
